<template>
  <section>
    <ValidationObserver ref="validate">
      <b-form @submit.prevent="save()">
        <b-form-group label="Nome" label-for="input-1" label-size="sm">
          <ValidationProvider
            name="quantidade"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-input v-model="model.name" required />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-form-group>
        <b-form-group label="Quantidade" label-for="input-1" label-size="sm">
          <ValidationProvider
            name="quantidade"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-input type="number" v-model="model.quantity" required />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-form-group>
        <div class="float-right">
          <b-button
            type="button"
            variant="secondary"
            @click="$emit('closeModal', false)"
            >Cancelar</b-button
          >
          <b-button type="submit" variant="success" class="ml-1"
            >Salvar</b-button
          >
        </div>
      </b-form>
    </ValidationObserver>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  props: {
    store_id: {
      type: Number,
      required: true
    },
    id_: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      users: [],
      cities: [],
      model: {
        name: "",
        quantity: 0,
        store_id: this.store_id
      }
    };
  },
  created() {
    if (this.id_) {
      const request = new Service();
      request.get(`product/${this.id_}`).then(res => {
        this.model = res;
      });
    }
  },
  methods: {
    async save() {
      const valid = await this.$refs.validate.validate();
      if (!valid) {
        this.$toast.open({
          position: "top",
          message: "Preencha os campos obrigatórios",
          type: "warning"
          // all other options
        });
        return;
      }

      const request = new Service();
      if (this.id_) {
        request.put(`/product/${this.id_}`, this.model).then(() => {
          this.$emit("closeModal", true);
        });
      } else {
        request.post(`/product`, this.model).then(() => {
          this.$emit("closeModal", true);
        });
      }
    }
  },
  watch: {}
};
</script>
<style></style>
