<template>
  <section>
    <div
      class="row d-flex justify-content-between pl-4 pr-4 align-items-center"
    >
      <h2>Bem-Vindo(a), {{ currentUser.name }}</h2>
      <b-button v-b-modal.modal-cp><b-icon-lock-fill /> Alterar Senha</b-button>
    </div>
    <hr />
    <div>
      <b-modal
        header-bg-variant="outline-success"
        style="border-radius: 10px;"
        size="md"
        id="modal-1"
        hide-footer
        centered
        @hidden="closeModal(false)"
        title="Registrar produto"
      >
        <modal-register-product
          :store_id="this.value.id"
          :id_="this.id_"
          @closeModal="closeModal"
        ></modal-register-product>
      </b-modal>
    </div>
    <div>
      <b-modal
        header-bg-variant="secondary"
        style="border-radius: 10px;"
        id="modal-2"
        centered
        @hidden="closeModal(false)"
        title="Deletar registro"
        hide-footer
      >
        <modal-delete
          @closeModal="closeModal"
          @isDelete="isDelete"
          :name="name"
        ></modal-delete>
      </b-modal>
    </div>
    <div>
      <change-password @closeModal="closeModal" />
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form-group class="float-left col-md-4 ml-0">
          <v-select
            :options="store"
            placeholder="Selecione a loja..."
            label="name"
            v-model="value"
          ></v-select>
        </b-form-group>
        <b-button v-b-modal.modal-1 variant="success" class="float-right mb-2">
          <a>Novo produto</a>
        </b-button>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Produto</th>
                <th scope="col">Quantide</th>
                <th scope="col">Estabelecimento</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in products" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ value.name }}</td>
                <td></td>
                <td>
                  <div class="d-flex justify-content-around">
                    <b-icon-pencil-square
                      v-b-modal.modal-1
                      @click="setId(item.id)"
                      class="actions"
                      style="color: #00f742;"
                    />

                    <b-icon-trash-fill
                      @click="setName(item.name), setId(item.id)"
                      v-b-modal.modal-2
                      class="actions"
                      style="color: #ff0000;"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <span>{{ this.totalSize }} itens no total</span>
          </div>
          <div
            class="float-right col-md-9 col-sm-12 d-flex justify-content-end"
          >
            <div class="d-flex justify-content-around">
              <div class="actions">
                <span @click="previousPage">« Anterior</span>
              </div>
              <div class="actions" @click="nextPage">
                <span>Próxima »</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Service from "../../services";
import ModalRegisterProduct from "../../components/Modal/ModalRegisterProduct";
import ChangePassword from "../../components/Modal/ModalChangePassword";
import ModalDelete from "../../components/Modal/ModalDelete";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalRegisterProduct,
    ModalDelete,
    ChangePassword
  },
  data() {
    return {
      value: "",
      name: "",
      id_: undefined,
      table: [],
      store: [],
      products: [],
      page: 1,
      totalSize: 1
    };
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      // this.totalPage = Math.ceil(this.table.length / this.size);
      const request = new Service();
      request.get(`store/my`).then(res => {
        this.store = res.stores;
        if (res.stores[0].id) this.value = res.stores[0];
      });
      // let user = JSON.parse(localStorage.getItem("user"));
    },
    setId(id) {
      this.id_ = id;
    },
    setName(name) {
      this.name = name;
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.getProducts();
      }
    },
    isDelete(value) {
      const request = new Service();
      if (value) {
        request
          .delete("product", this.id_)
          .then(() => this.getProducts(this.value.id));
      }
      this.closeModal(false);
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalSize / 12)) {
        this.page = this.page + 1;
        this.getProducts();
      }
    },
    getProducts(id) {
      const request = new Service();
      request.get(`product/store/${id}`, { page: this.page }).then(res => {
        this.products = res.products.data;
        this.totalSize = res.products.total;
      });
    },
    closeModal(value) {
      if (value) {
        this.$toast.open({
          position: "top",
          message: "Operação realizada com sucesso",
          type: "success"
        });
        this.getProducts(this.value.id);
      }
      this.id_ = undefined;
      this.$bvModal.hide("modal-1");
      this.$bvModal.hide("modal-2");
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  watch: {
    value: function() {
      this.getProducts(this.value.id);
    }
  }
};
</script>
<style></style>
