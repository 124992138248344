<template>
  <svg
    :width="this.width"
    :height="this.height"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.76261 18.6629V44.2439M4.76261 18.6629H45.2374M4.76261 18.6629V18.6629C4.32996 18.6629 3.97923 18.3122 3.97923 17.8795V17.447C3.97923 16.7754 4.52362 16.231 5.19516 16.231H45.2374M4.76261 44.2439V44.2439C4.32996 44.2439 3.97923 44.5946 3.97923 45.0273V45.6219C3.97923 46.383 4.59621 47 5.35729 47H44.9039C45.6649 47 46.2819 46.383 46.2819 45.6219V45.2884C46.2819 44.7115 45.8143 44.2439 45.2374 44.2439V44.2439M4.76261 44.2439H45.2374M45.2374 44.2439V18.6629M45.2374 18.6629V18.6629C45.67 18.6629 46.0208 18.3122 46.0208 17.8795V17.0144C46.0208 16.5818 45.67 16.231 45.2374 16.231V16.231M4.76261 15.9451L4.76261 13.1319C4.76261 12.7198 4.62473 12.3195 4.37092 11.9948V11.9948C4.11711 11.6701 3.97923 11.2698 3.97923 10.8577V10.5733M3.97923 10.5733C2.67359 10.5733 2.67359 8 3.97923 8H18.573M3.97923 10.5733H18.573M46.0208 10.5733V10.7946C46.0208 11.2071 45.8829 11.6077 45.6291 11.9328V11.9328C45.3753 12.2579 45.2374 12.6586 45.2374 13.071L45.2374 16.231M46.0208 10.5733C47.3264 10.5733 47.3264 8 46.0208 8H30.9326M46.0208 10.5733H30.9326"
      :stroke="this.iconColor"
      stroke-width="3"
    />
    <path
      d="M18 44V25C18 24.4477 18.4477 24 19 24H30C30.5523 24 31 24.4477 31 25V44"
      :stroke="this.iconColor"
      stroke-width="3"
    />
    <rect
      x="19"
      y="3"
      width="12"
      height="12"
      rx="3"
      :stroke="this.iconColor"
      stroke-width="3"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: [Number, String],
      default: 50
    },
    iconColor: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style></style>
