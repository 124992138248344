<template>
  <section>
    <form class="was-validated" @submit.prevent="save">
      <div class="form-row">
        <div v-if="model.id" class="col-md-12 mb-3">
          <label for="validationCustom01">Nome</label>
          <input
            name="nome_cidade"
            class="form-control"
            id="validationCustom02"
            placeholder="Nome"
            v-model="model.name"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom01">Município</label>
          <select
            name="cidade"
            id="validationCustom01"
            class="custom-select"
            required
            v-model="model.geocity_id"
          >
            <option
              v-for="(item, index) in geocities"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3 d-flex flex-column">
          <label for="validationCustomIm">Imagem do Município</label>
          <b-file
            id="validationCustomIm"
            choose-label="Escolha um arquivo"
            accept="image/jpeg, image/png"
            browse-text="Buscar"
            placeholder="Escolha um arquivo ou arraste aqui..."
            @change="setImage"
          />
          <span class="text-muted small"
            >O tamanho ideal para o relatório é de 260x120(px)</span
          >
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="validationCustom02">Leitos disponíveis</label>

          <input
            type="number"
            name="leitos"
            class="form-control"
            id="validationCustom02"
            placeholder="Nome"
            v-model="model.available_beds"
            required
          />
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Quantidade de respiradores</label>

          <input
            type="number"
            name="leitos"
            class="form-control"
            id="validationCustom03"
            placeholder="Nome"
            v-model="model.occupied_beds"
            required
          />
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Quantidade de UTI's</label>

          <input
            type="number"
            name="uti"
            class="form-control"
            id="validationCustom03"
            placeholder="Nome"
            v-model="model.available_uti"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationCustom04">Website</label>

          <input
            type="text"
            name="leitos"
            class="form-control"
            id="validationCustom04"
            v-model="model.website"
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom05">Instagram</label>

          <input
            type="text"
            name="leitos"
            class="form-control"
            id="validationCustom05"
            v-model="model.instagram"
          />
        </div>
      </div>
      <div
        v-if="districts.length > 0"
        id="districts"
        style="max-height: 500px; overflow-y: scroll; overflow-x: hidden; margin-bottom: 20px"
      >
        <label>Bairros/Distritos</label>
        <b-row v-for="(district, index) in districts" :key="index">
          <b-form-group class="col-md-5" label-size="sm" label-for="input-4">
            <span>Nome</span>

            <b-form-input
              size="sm"
              v-model="districts[index].name"
              :value="district.name"
            />
          </b-form-group>
          <b-form-group class="col-md-3" label-size="sm" label-for="input-4">
            <span>Latitude</span>
            <b-form-input
              size="sm"
              v-model="districts[index].latitude"
              :value="district.latitude"
            />
          </b-form-group>
          <b-form-group class="col-md-3" label-size="sm" label-for="input-4">
            <span>Longitude</span>
            <b-form-input
              size="sm"
              v-model="districts[index].longitude"
              :value="district.longitude"
            />
          </b-form-group>
          <b-form-group
            class="col-md-1 d-flex flex-column justify-content-center"
            style="padding-right: 20px"
            label-size="sm"
            label-for="input-4"
          >
            <h5 style="color:transparent">Longitude</h5>

            <b-row>
              <b-icon-check-circle
                type="submit"
                style="color: green; text-align:center; padding-right: 5px"
                scale="1.5"
                @click="saveDistrict(index)"
              ></b-icon-check-circle>
              <b-icon-trash
                type="submit"
                style="color: red; text-align:center; padding-left: 5px"
                scale="1.5"
                @click="deleteDistrict(index)"
              ></b-icon-trash>
            </b-row>
          </b-form-group>
        </b-row>
      </div>

      <div class="float-right">
        <b-button variant="secondary" @click="$emit('closeModal', false)"
          >Cancelar</b-button
        >
        <b-button variant="success" type="submit" class="ml-1">Salvar</b-button>
      </div>
    </form>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  props: {
    id_: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      showDistrictForm: false,
      geocities: [],
      districts: [],
      model: {
        name: "",
        geocity_id: { id: undefined, name: "" },
        occupied_beds: 0,
        available_beds: 0,
        available_uti: 0,
        website: "",
        instagram: ""
      },
      image: null
    };
  },
  created() {
    const request = new Service();
    request.get("city/geocity").then(res => {
      this.geocities = res[0];
    });
    if (this.id_) {
      request.get(`city/districts/${this.id_}`).then(res => {
        this.districts = res;
      });
      request.get(`city/${this.id_}`).then(res => {
        this.model = res;
      });
    }
  },
  methods: {
    setImage(event) {
      this.image = event.target.files[0];
    },
    saveDistrict(index) {
      const request = new Service();

      request
        .put(
          `city/districts/${this.districts[index].id}`,
          this.districts[index]
        )
        .then(() => {
          this.$toast.open({
            position: "top",
            message: "Operação realizada com sucesso!",
            type: "success"
            // all other options
          });
        });
    },
    deleteDistrict(index) {
      const request = new Service();
      request.delete(`city/districts`, this.districts[index].id).then(() => {
        this.districts.splice(index, 1);
        this.$toast.open({
          position: "top",
          message: "Operação realizada com sucesso!",
          type: "success"
          // all other options
        });
      });
    },
    async save() {
      let obj = Object.assign({}, this.model);

      let res = this.geocities.find(e => e.id === obj.geocity_id);
      if (!obj.id) {
        obj.name = res.name.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
          return a.toUpperCase();
        });
      }
      delete obj.geocity;

      const request = new Service();

      if (obj.id) {
        await request.put(`city/${obj.id}`, obj);
        if (this.image) {
          const form = new FormData();
          form.append("image", this.image);
          form.append("id", obj.id);
          await request.post(`uploader/district`, form);
        }
        this.$emit("closeModal", true);
      } else {
        const res = await request.post("city", obj);
        if (this.image) {
          const form = new FormData();
          form.append("image", this.image);
          form.append("id", res.id);
          await request.post(`uploader/district`, form);
        }
        this.$emit("closeModal", true);
      }
    }
  }
};
</script>
<style>
#districts {
  /* overflow-x: ; */
}
</style>
