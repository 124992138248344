<template>
  <div class="d-flex justify-content-center">
    <div style="height: 35rem; width: 80%;">
      <div id="map"></div>
      <div v-if="!shouldPopulate || isLoading" id="map-overflow">
        <b-button @click="allowPopulate()" variant="outline-light" size="lg">
          <b-spinner v-if="isLoading" class="ml-5 mr-5" variant="dark" />
          <span v-else>Exibir mapa de pontos</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import L from "leaflet";
import HeatmapOverlay from "leaflet-heatmap";
import Service from "../../services";
import { mapGetters } from "vuex";
export default {
  props: {
    readyUpdate: {
      type: Number
    }
  },
  data() {
    return {
      shouldPopulate: false,
      isLoading: false,
      colors: {
        confirmed: {
          name: "Confirmado",
          color: "EA3546"
        },
        suspicious: {
          name: "Suspeito",
          color: "F86624"
        },
        discarded: {
          name: "Descartado",
          color: "cccccc"
        },
        recovered: {
          name: "Recuperado",
          color: "00B963"
        },
        lethal: {
          name: "Óbito",
          color: "000000"
        }
      },
      markerGroup: null,
      geoLayer: null,
      stLayer: null,
      map: null,
      heat: null,
      default: {
        fillOpacity: 0.2,
        weight: 2,
        opacity: 1,
        color: "red",
        dashArray: "6"
      }
    };
  },
  computed: {
    ...mapGetters(["currentMeta"])
  },
  mounted() {
    this.setup();
  },
  methods: {
    allowPopulate() {
      this.isLoading = true;
      this.shouldPopulate = true;
      this.populate();
    },
    setup() {
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
      });
      this.map = L.map("map", {
        dragging: !L.Browser.mobile,
        tap: !L.Browser.mobile
      }).setView([-5.142408, -38.095851], 7);
      const osm = new L.tileLayer(
        "http://{s}.tile.osm.org/{z}/{x}/{y}.png"
      ).addTo(this.map);
      this.heat = new HeatmapOverlay({
        radius: 25,
        maxOpacity: 0.8,
        useLocalExtrema: true,
        latField: "lat",
        lngField: "lng"
      });
      this.geoLayer = L.geoJson(null, {
        style: this.style
      });
      this.stLayer = L.geoJson(null, {
        style: () => {
          return {
            color: "#088FE4",
            fillOpacity: 0.1,
            dashArray: "0"
          };
        }
      });
      this.stLayer.addTo(this.map);
      this.geoLayer.addTo(this.map);
      L.control
        .layers(
          {
            "Visão de Casos": osm
          },
          {
            "Mapa de Calor": L.layerGroup([this.heat])
          }
        )
        .addTo(this.map);
      this.markerGroup = L.layerGroup().addTo(this.map);
      const request = new Service();
      request.get(`city/geocity/${this.currentMeta.id}`).then(res => {
        const data = {
          features: [
            {
              type: "Feature",
              geometry: JSON.parse(res.shape)
            }
          ]
        };
        this.geoLayer.addData(data);
        this.map.fitBounds(this.geoLayer.getBounds());
      });
      request.get("geolayer/ce_state").then(res => {
        this.stLayer.addData(JSON.parse(res));
      });
    },
    populate(query = "") {
      if (!this.shouldPopulate) return;
      this.heat.setData({ data: [] });
      this.markerGroup.clearLayers();
      const request = new Service();
      request.get("incident/bycity", { query }).then(res => {
        let coords = [];
        res.forEach(e => {
          const marker = L.marker([Number(e.latitude), Number(e.longitude)], {
            icon: L.divIcon({
              className: "ship-div-icon",
              html: `<img src=http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${this.selectColor(
                e.incident_type
              )}&chf=a,s,ee00FFFF />`
            })
          });
          marker
            .bindPopup(
              `
            <h3 style="color:#${this.colors[e.incident_type].color}">${
                this.colors[e.incident_type].name
              }</h3>
            <p>
            <b>Caso:</b> #${e.id}<br/>
            <b>Nome:</b> ${e.name}<br/>
            <b>Idade:</b> ${Math.trunc(
              (Date.now() - new Date(e.birth)) / 31557600000
            )} anos<br/>
            <b>Endereço:</b> ${e.street}<br/>
            <b>Internado:</b> ${["Não", "Sim"][e.interned]}<br/>
            ${
              e.interned
                ? "<b>Internado em:</b> " +
                  new Date(e.interned_at).toLocaleString().split(" ")[0] +
                  "<br/>"
                : ""
            }
            <b>Notificado na cidade:</b> ${
              e.city.id == e.report_city_id ? "Sim" : "Não"
            }<br/>
            ${
              e.city.id != e.report_city_id
                ? "<b>Cidade Notificadora:</b> " + e.report_city.name + "<br/>"
                : ""
            }
            <b>Unidade Notificadora:</b> ${
              [
                "Atenção Primária",
                "Hospital Público",
                "Hospital Particular",
                "Laboratório Particular"
              ][e.reporting_unity]
            }<br/>
            </p>
            <p>
                <b>Observações:</b> ${e.observations}<br/>
            </p>
            <p>
                <b>Atualização:</b> ${new Date(
                  e.updated_at
                ).toLocaleString()}<br/>
                <b>Cadastro:</b> ${new Date(e.created_at).toLocaleString()}<br/>
            </p>
          `
            )
            .addTo(this.markerGroup);
          coords.push({
            lat: Number(e.latitude),
            lng: Number(e.longitude),
            value: 1
          });
        });
        this.heat.setData({ data: coords });
        if (this.isLoading) this.isLoading = false;
      });
    },
    style() {
      return this.default;
    },
    selectColor(incident) {
      switch (incident) {
        case "suspicious":
          return this.colors.suspicious.color;
        case "confirmed":
          return this.colors.confirmed.color;
        case "discarded":
          return this.colors.discarded.color;
        case "lethal":
          return this.colors.lethal.color;
        case "recovered":
          return this.colors.recovered.color;
      }
    }
  },
  watch: {}
};
</script>
<style scoped>
#map {
  height: 35rem;
  width: 100%;
}
#map-overflow {
  position: absolute;
  background-color: #00000077;
  height: 35.2rem;
  width: 79%;
  transform: translateY(-35.2rem);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}
</style>
