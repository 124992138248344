<template>
  <section>
    <b-modal
      header-bg-variant="outline-success"
      style="border-radius: 10px;"
      size="md"
      id="modal-cp"
      hide-footer
      centered
      scrollable
      @hidden="closeModal(false)"
      title="Alterar Senha"
    >
      <b-form @submit.prevent="changePassword">
        <b-form-group label="Senha atual:">
          <b-input
            v-model="current_password"
            type="password"
            name="senha_atual"
            required
          />
        </b-form-group>
        <b-form-group @keyup="sameNewPassword" label="Nova senha:">
          <b-input
            v-model="new_password"
            type="password"
            name="nova_senha"
            required
          />
        </b-form-group>
        <b-form-group
          @keyup="sameNewPassword"
          label="Confirmação da nova senha:"
        >
          <b-input
            v-model="new_password_c"
            type="password"
            name="nova_senha"
            required
          />
        </b-form-group>
        <div class="float-right">
          <b-button variant="secondary" @click="$bvModal.hide('modal-cp')"
            >Cancelar</b-button
          >
          <b-button
            :disabled="!sameNewPassword"
            variant="success"
            @click="changePassword"
            class="ml-1 is-valid"
            >Alterar</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </section>
</template>
<script>
import Service from "../../services";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      current_password: "",
      new_password: "",
      new_password_c: ""
    };
  },
  methods: {
    changePassword() {
      if (this.sameNewPassword && this.current_password.length > 0) {
        const service = new Service();
        service
          .put(`user/${this.currentUser.id}`, {
            old_password: this.current_password,
            new_password: this.new_password
          })
          .then(() => {
            this.$bvModal.hide("modal-cp");
            this.current_password = "";
            this.new_password = "";
            this.new_password_c = "";
          })
          .catch(() => {
            this.$toast.open({
              position: "top",
              message: "Não foi possível modificar a senha.",
              type: "error"
            });
          });
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-cp");
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    sameNewPassword() {
      return this.new_password == this.new_password_c;
    }
  }
};
</script>
