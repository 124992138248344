<template>
  <section>
    <h5>Dados do paciente</h5>

    <b-row class="d-flex justify-content-center">
      <div class="col-md-4 d-flex flex-row">
        <b>Nome:</b>
        <span>{{ model.name }}</span>
      </div>
      <div class="col-md-4 d-flex flex-row">
        <b>Nascimento:</b>
        <span>{{ showDate(model.birth) }}</span>
      </div>
      <div class="col-md-4 d-flex flex-row">
        <b>Contato:</b>
        <span>{{ model.contact }}</span>
      </div>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-6 d-flex flex-row">
        <b>Endereço:</b>
        <span>{{ model.street }}, {{ model.district.name }}</span>
      </div>
      <div class="col-md-6 d-flex flex-row">
        <b>Cidade:</b>
        <span>{{ model.city[0].name }}</span>
      </div>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-6 d-flex flex-row">
        <b>Gênero:</b>
        <span>{{ model.gender == "F" ? "Feminino" : "Masculino" }}</span>
      </div>
      <div class="col-md-6 d-flex flex-row">
        <div v-if="model.gender == 'F'">
          <b>Gestante:</b>
          <span>{{ model.pregnant == "0" ? "Não" : "Sim" }}</span>
        </div>
      </div>
    </b-row>
    <hr />
    <h5>Dados de vacinação</h5>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-12 d-flex flex-row">
        <b>COVID-19:</b>
        <span>{{ !!model.vaccinated ? "Sim" : "Não" }}</span>
      </div>
    </b-row>

    <hr />
    <h5>Dados do caso</h5>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-4 d-flex flex-row">
        <b>Tipo de incidente:</b>
        <span>{{ typeEnum(model.incident_type) }}</span>
      </div>
      <div class="col-md-4 d-flex flex-row">
        <b>Situação do paciente:</b>
        <span>{{ typeEnumIncidentType(model.interned) }}</span>
      </div>
      <div class="col-md-4 d-flex flex-row">
        <b>Notificado em:</b>
        <span>{{ showDate(model.created_at) }}</span>
      </div>
    </b-row>
    <b-row v-if="model.isolated == '0'" class="d-flex justify-content-center">
      <div class="col-md-6 d-flex flex-row">
        <b>Data da internação:</b>
        <span>{{ showDate(model.interned_at) }}</span>
      </div>
      <div class="col-md-6 d-flex flex-row">
        <b>Data da alta da internação:</b>
        <span>{{ showDate(model.discharged_at) }}</span>
      </div>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-6 d-flex flex-row">
        <b>Unidade notificadora:</b>
        <span>{{ getReportingUnity(model.reporting_unity) }}</span>
      </div>

      <div class="col-md-6 d-flex flex-row">
        <b>Primeiros sintomas:</b>
        <span>{{ showDate(model.onset_at) }}</span>
      </div>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-6 d-flex flex-column">
        <b>Sintomas:</b>
        <span v-for="(item, index) in model.symptoms" :key="index">
          {{ item.name }}
        </span>
      </div>
      <div class="col-md-6 d-flex flex-column">
        <b>Comorbidades:</b>
        <span v-for="(item, index) in model.morbities" :key="index">
          {{ item.name }}
        </span>
      </div>
    </b-row>
    <hr />
    <h5>Dados do exame</h5>
    <b-row class="d-flex justify-content-center">
      <div class="col-md-4 d-flex flex-row">
        <b>Realizado:</b>
        <span>{{ model.examined == "1" ? "Sim" : "Não" }}</span>
      </div>

      <div class="col-md-4 d-flex flex-row">
        <b>Data da coleta:</b>
        <span>{{ showDate(model.collected_at) }}</span>
      </div>
      <div class="col-md-4 d-flex flex-row">
        <b>Data da resultado:</b>
        <span>{{ showDate(model.result_at) }}</span>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-4 d-flex flex-row">
        <b>Tipo de laboratório:</b>
        <span>
          {{
            model.public_clinic == null
              ? "Não informado"
              : model.public_clinic == "1"
              ? "Público"
              : "Privado"
          }}
        </span>
      </div>

      <div class="col-md-4 d-flex flex-row">
        <b>Tipo de teste:</b>
        <span>
          {{ getTestType(model.test_type) }}
        </span>
      </div>
    </b-row>
  </section>
</template>
<script>
import Service from "../../services";

export default {
  props: {
    id_: {
      type: Number
    }
  },
  data() {
    return {
      type: [
        { label: "Confirmado", value: "confirmed" },
        { label: "Suspeito", value: "suspicious" },
        { label: "Curado", value: "recovered" },
        { label: "Descartado", value: "discarded" },
        { label: "Óbito", value: "lethal" }
      ],
      model: {
        accepted: 1,
        birth: "",
        city: [{ name: "" }],
        district: { name: "" },
        collected_at: null,
        contact: "",
        created_at: "",
        discharged_at: "",
        district_id: undefined,
        examined: undefined,
        gender: "",
        id: undefined,
        incident_type: "",
        interned: undefined,
        interned_at: "",
        isolated: undefined,
        latitude: "",
        longitude: "",
        morbities: [],
        name: "",
        observations: "",
        onset_at: "",
        pregnant: undefined,
        public_clinic: undefined,
        report_city_id: undefined,
        reporting_unity: "",
        result_at: null,
        street: "",
        symptoms: [],
        updated_at: ""
      }
    };
  },
  async mounted() {
    const request = new Service();
    const model = await request.get(`incident/${this.id_}`);
    if (model) {
      this.model = model;
    }
  },
  methods: {
    showDate(date) {
      if (date) {
        let [ano, mes, dia] = date.split("-", 3);
        return `${dia}/${mes}/${ano}`;
      } else {
        return "Sem informação";
      }
    },
    getTestType(type) {
      let arr = [
        {
          id: 0,
          label: "Teste rápido - anticorpo"
        },
        {
          id: 1,
          label: "Teste rápido - antígeno"
        },
        {
          id: 2,
          label: "RT - PCR"
        },
        {
          id: 3,
          label: "Enzimaimunoensaio - ELISA"
        },
        {
          id: 4,
          label: "Eletroquimioluminescência - ECLIA"
        },
        {
          id: 5,
          label: "Quimioluminescência- CLIA"
        }
      ];
      const res = arr.find(e => e.id === type);
      if (res) {
        return res.label;
      }
    },
    typeEnum(value) {
      const res = this.type.filter(e => e.value == value)[0];
      if (res != undefined) {
        return res.label || "";
      }
    },
    typeEnumIncidentType(situation) {
      switch (situation) {
        case 0:
          return "Isolado";
        case 1:
          return "Internado";
        case 2:
          return "Liberado";
      }
    },
    getReportingUnity(index) {
      let reporting_unity = [
        { label: "Atenção Primária", value: "0" },
        { label: "Hospital Público", value: "1" },
        { label: "Hospital Particular", value: "2" },
        { label: "Unidade de Pronto Atendimento (UPA)", value: "3" },
        { label: "Laboratório Privado", value: "4" }
      ];
      let res = reporting_unity.filter(e => e.value == index)[0];
      if (res != undefined) return res.label;
    }
  }
};
</script>
<style>
div {
  margin: 0 0 3px 0;
}
b {
  color: black;
}
span {
  padding-left: 5px;
}
h6 {
  text-align: justify;
}
</style>
