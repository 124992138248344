<template>
  <section>
    <form class="was-validated" @submit.prevent="importDistricts">
      <b-row class="pl-3 pr-3">
        <b-form-file
          style="col-6"
          v-model="jsonFile"
          :state="Boolean(jsonFile)"
          placeholder="Escolha um arquivo ou arraste aqui..."
          drop-placeholder="Arraste um arquivo aqui..."
          @change="readFile"
        ></b-form-file>
      </b-row>
      <b-row class="pl-3 pr-3 mt-4 mb-3">
        <label for="cities">Municípios Lidos:</label>
        <b-select
          id="cities"
          text-field="name"
          value-field="districts"
          v-model="district"
          :options="jsonContent"
        />

        <b-list-group
          ref="rdistricts"
          class="w-100 ml-1 mr-1"
          style="max-height: 300px; overflow-y:scroll;"
        >
          <b-list-group-item
            :variant="item.editing ? 'success' : 'default'"
            v-for="item in district"
            :key="item.index"
            class="d-flex justify-content-between align-items-center"
          >
            <b-form-checkbox
              size="lg"
              v-model="item.checked"
              value="true"
              unchecked-value="false"
            />
            <div class="d-flex flex-column">
              <span v-if="!item.editing">{{ item.name }}</span>
              <div v-else>
                <label>Bairro:</label>
                <b-input
                  placeholder="Nome do bairro"
                  type="text"
                  v-model="item.name"
                />
              </div>
              <span v-if="!item.editing" class="small"
                >Lat: {{ item.latitude }}<br />Lng: {{ item.longitude }}</span
              >
              <div v-else>
                <label>Latitude:</label>
                <b-input
                  placeholder="Latitude"
                  step="any"
                  type="number"
                  v-model="item.latitude"
                />
                <label>Longidute:</label>
                <b-input
                  placeholder="Longitude"
                  step="any"
                  type="number"
                  v-model="item.longitude"
                />
              </div>
            </div>
            <b-button
              size="sm"
              variant="success"
              @click="item.editing = !item.editing"
            >
              <b-icon-pencil v-if="!item.editing" />
              <b-icon-file-check v-else />
            </b-button>
          </b-list-group-item>
        </b-list-group>
        <b-button @click="insertDistrict" variant="success" class="w-100 mb-2"
          ><b-icon-plus /> Adicionar Bairro</b-button
        >
        <label for="target_city">Município Alvo:</label>
        <b-select
          id="target_city"
          v-model="target_city"
          text-field="name"
          value-field="id"
          :options="cities"
        />
      </b-row>
      <div v-if="errors.length" class="text-center">
        <span
          style="color: red; width: 100%;"
          v-for="(item, index) in errors"
          :key="index"
        >
          Não foi possível adicionar {{ item.name }}, já existente!<br />
        </span>
      </div>
      <div v-if="!importing" class="float-right">
        <b-button variant="secondary" @click="$emit('closeModal', false)"
          >Cancelar</b-button
        >
        <b-button
          variant="success"
          type="submit"
          class="ml-1"
          :disabled="!target_city"
          >Importar</b-button
        >
      </div>
      <div v-else>
        <b-progress
          variant="success"
          :value="queue"
          :max="initialQueue"
          class="w-100"
          style="height: 2rem;"
        ></b-progress>
      </div>
    </form>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  data() {
    return {
      jsonFile: null,
      jsonContent: null,
      district: [],
      cities: [],
      target_city: null,
      indexer: 0,
      importing: false,
      success_counter: 0,
      queue: 0,
      initialQueue: 0,
      errors: []
    };
  },
  methods: {
    insertDistrict() {
      this.district.unshift({
        name: "",
        latitude: 0,
        longitude: 0,
        editing: true,
        checked: true,
        index: this.indexer++
      });
      this.$refs.rdistricts.scrollTo(0, 0);
    },
    readFile(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = r => {
        let cidades = {};
        JSON.parse(r.target.result).features.forEach(d => {
          if (d.properties.NOME == "" || d.properties.NOME == null) return;
          const city = d.properties.NOME;
          if (cidades[city] == null) cidades[city] = [];
          cidades[city].push({
            name: d.properties.LOCALIDADE,
            latitude: d.geometry ? d.geometry.coordinates[1] : 0,
            longitude: d.geometry ? d.geometry.coordinates[0] : 0,
            checked: true,
            editing: false,
            index: this.indexer++
          });
        });
        let cidades_array = [];
        Object.keys(cidades).forEach(c => {
          cidades_array.push({
            name: c,
            districts: cidades[c]
          });
        });
        this.jsonContent = cidades_array;
      };
      reader.readAsText(file);
    },
    async importDistricts() {
      const filteredDistricts = this.district.filter(d => d.checked);
      this.errors = [];
      this.importing = true;
      this.initialQueue = filteredDistricts.length;
      this.queue = 0;
      this.success_counter = 0;
      const request = new Service();
      filteredDistricts.forEach(async d => {
        try {
          await request.post("/city/districts", {
            name: d.name,
            latitude: d.latitude,
            longitude: d.longitude,
            city_id: this.target_city
          });
          this.success_counter++;
        } catch (error) {
          this.errors.push({ name: d.name, error: error.message });
        }
        this.queue++;
      });
    }
  },
  watch: {
    queue(value) {
      if (value == this.initialQueue) {
        this.importing = false;
        this.$toast.open({
          position: "top",
          message: `${this.success_counter} bairros de ${this.initialQueue} foram importados com sucesso!`,
          type: "success"
          // all other options
        });
      }
    }
  },
  mounted() {
    const request = new Service();
    request.get("/city", { size: 9999 }).then(res => {
      this.cities = res.data;
    });
  }
};
</script>
