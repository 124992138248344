<template>
  <section>
    <form class="was-validated" @submit.prevent="save">
      <b-form-group label="Nome do estado" label-size="sm" label-for="input-1">
        <b-input v-model="model.name" id="input-1" required></b-input>
      </b-form-group>
      <b-form-group
        label="Quantidade de casos confirmados"
        label-size="sm"
        label-for="input-2"
      >
        <b-input v-model="model.qty_cases" id="input-2" required></b-input>
      </b-form-group>
      <b-form-group
        label="Quantidade de casos com óbito"
        label-size="sm"
        label-for="input-3"
      >
        <b-input v-model="model.qty_lethal" id="input-3" required></b-input>
      </b-form-group>
      <div class="float-right">
        <b-button variant="secondary" @click="$emit('closeModal', false)"
          >Cancelar</b-button
        >
        <b-button variant="success" type="submit" class="ml-1">Salvar</b-button>
      </div>
    </form>
  </section>
</template>

<script>
import Service from "../../services";
export default {
  props: {
    id_: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      model: {
        name: "",
        qty_cases: "",
        qty_lethal: ""
      }
    };
  },
  mounted() {
    const request = new Service();
    if (this.id_) {
      request.get(`state/${this.id_}`).then(res => {
        this.model = res;
      });
    }
  },
  methods: {
    async save() {
      const request = new Service();
      if (this.id_) {
        request.put(`state/${this.id_}`, this.model).then(() => {
          this.$emit("closeModal", true);
        });
      } else {
        request.post("state", this.model).then(() => {
          this.$emit("closeModal", true);
        });
      }
    }
  }
};
</script>

<style></style>
