<template>
  <component :is="useComponent" />
</template>
<script>
import Admistrador from "../../views/User/Admistrador";
import Municipio from "../../views/User/Municipio";
import Store from "../../views/User/Store";
export default {
  components: {
    Admistrador,
    Municipio,
    Store
  },
  computed: {
    useComponent() {
      const { role } = JSON.parse(localStorage.getItem("user"));
      switch (role) {
        case "admin":
          return "Admistrador";
        case "store":
          return "Store";
        case "city":
          return "Municipio";
      }
      return "";
    }
  }
};
</script>
