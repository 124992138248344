<template>
  <section>
    <h2>Bem-Vindo Administrador</h2>

    <hr />
    <div class="row">
      <div class="col-md-3">
        <div class="grid-card-2">
          <div
            class="card"
            :class="{ active: this.valueClickForShowTable == 1 }"
            @click="changeTable(1)"
          >
            <b-icon-person-plus scale="3" style="height: 50px" />

            <h6 :class="{ active: this.valueClickForShowTable == 1 }">
              {{ items[0].nome }}
            </h6>
          </div>
          <div
            class="card"
            :class="{ active: this.valueClickForShowTable == 2 }"
            @click="changeTable(2)"
          >
            <b-icon-building scale="3" style="height: 50px" />

            <h6 :class="{ active: this.valueClickForShowTable == 2 }">
              {{ items[1].nome }}
            </h6>
          </div>
          <div
            class="card"
            :class="{ active: this.valueClickForShowTable == 3 }"
            @click="changeTable(3)"
          >
            <icon-estabelecimento :iconColor="getColorActive(3)" />

            <h6 :class="{ active: this.valueClickForShowTable == 3 }">
              {{ items[2].nome }}
            </h6>
          </div>
          <div
            class="card"
            :class="{ active: this.valueClickForShowTable == 4 }"
            @click="changeTable(4)"
          >
            <b-icon-map scale="3" style="height: 50px" />

            <h6 :class="{ active: this.valueClickForShowTable == 4 }">
              {{ items[3].nome }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div v-if="valueClickForShowTable == 1">
          <table-user></table-user>
        </div>
        <div v-if="valueClickForShowTable == 2">
          <table-city></table-city>
        </div>
        <div v-if="valueClickForShowTable == 3">
          <table-establishment></table-establishment>
        </div>
        <div v-if="valueClickForShowTable == 4">
          <table-state></table-state>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TableEstablishment from "./Table/establishment";
import TableUser from "./Table/user";
import TableCity from "./Table/city";
import TableState from "./Table/state";
import IconEstabelecimento from "../../assets/icon/estabelecimento";

export default {
  components: {
    IconEstabelecimento,
    TableState,
    "table-establishment": TableEstablishment,
    "table-city": TableCity,
    "table-user": TableUser
  },
  data() {
    return {
      items: [
        { nome: "Usuários", icon: "" } /* CRUD */,
        { nome: "Município", icon: "" } /* CRUD */,
        { nome: "Estabelecimento", icon: "" } /* CRUD */,
        { nome: "Estados", icon: "" } /* CRUD */
      ],
      table: [],
      valueClickForShowTable: 1
    };
  },
  methods: {
    changeTable(value) {
      this.valueClickForShowTable = value;
    },
    getColorActive(active) {
      return this.valueClickForShowTable == active
        ? "#fff"
        : "rgb(99, 110, 121)";
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "modal-state", "#btnShow");
    }
  }
};
</script>
<style lang="scss" scoped></style>
