<template>
  <section>
    <div style="min-height: 120px;">
      <div class="d-flex justify-content-center">
        <h4>
          Você tem certeza?
        </h4>
      </div>
      <div>
        <p>
          Deseja realmente excluir {{ this.name }}? Este processo não pode ser
          desfeito.
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-center justify-content-around">
      <b-button variant="secondary" @click="$emit('isDelete', false)"
        >Cancelar</b-button
      >
      <b-button variant="danger" @click="$emit('isDelete', true)"
        >Apagar</b-button
      >
    </div>
  </section>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    isDelete: {
      type: Function
    }
  },
  data() {
    return {};
  },
  watch: {}
};
</script>
