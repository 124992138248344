<template>
  <section>
    <form class="was-validated" @submit.prevent="save">
      <div class="form-row">
        <div class="d-flex justify-content-center col-md-12 mb-3">
          <b-form-radio-group
            id="validationCustom01"
            v-model="model.role"
            :options="roles"
            buttons
            button-variant="outline-primary"
            name="radio-btn-outline"
            text-field="label"
            value-field="value"
          ></b-form-radio-group>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom02">Nome</label>
          <input
            type="text"
            name="nome"
            class="form-control"
            id="validationCustom02"
            placeholder="Nome"
            v-model="model.name"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom04">Município</label>
          <select
            name="usuario"
            id="validationCustom04"
            class="custom-select"
            required
            v-model="model.city_id"
          >
            <option
              v-for="(item, index) in cities"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom02">E-mail</label>
          <input
            type="email"
            name="email"
            class="form-control"
            id="validationCustom02"
            placeholder="Email"
            v-model="model.email"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationCustom02">Senha</label>
          <input
            type="password"
            name="senha"
            class="form-control"
            id="validationCustom02"
            placeholder="Senha"
            v-model="model.password"
            :required="id_ == undefined"
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom02">Confirmar senha</label>
          <input
            type="password"
            name="senha"
            class="form-control"
            id="validationCustom02"
            placeholder="Confirmar senha"
            v-model="model.confirmPassword"
            :required="id_ == undefined"
          />
        </div>
      </div>
      <div class="float-right">
        <b-button variant="secondary" @click="$emit('closeModal', false)"
          >Cancelar</b-button
        >
        <b-button variant="success" type="submit" class="ml-1">Salvar</b-button>
      </div>
    </form>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  props: {
    id_: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      roles: [
        { label: "Administrador", value: "admin" },
        { label: "Município", value: "city" },
        { label: "Estabelecimento", value: "store" }
      ],
      cities: [],
      loadingScroll: true,
      confirmPassword: "",
      model: {
        name: "",
        city: undefined,
        role: "city",
        password: ""
      }
    };
  },
  async created() {
    const request = new Service();
    request.get("city").then(res => {
      this.cities = res.data.map(item => {
        return { id: item.id, name: item.name };
      });
    });
    if (this.id_) {
      const responseGetById = await request.get(`user/${this.id_}`);
      this.model = responseGetById;
    }
    this.loadingScroll = false;
  },
  methods: {
    async save() {
      let obj = Object.assign({}, this.model);
      if (obj.confirmPassword != obj.password) {
        this.$toast.open({
          position: "top",
          message: "As senha não coincidem!",
          type: "error"
          // all other options
        });
        return;
      }

      obj.city_id = obj.role == "admin" ? null : this.model.city_id;
      delete obj.city;

      const request = new Service();
      if (this.id_) {
        request.put(`user/${obj.id}`, obj).then(() => {
          this.$emit("closeModal", true);
        });
      } else {
        request.post("user", obj).then(() => {
          this.$emit("closeModal", true);
        });
      }
    }
  },
  watch: {
    "model.role": function() {
      if (this.model.role == "admin") this.model.geocity_id = undefined;
    },
    confirmPassword: function() {}
  }
};
</script>
