<template>
  <section>
    <div>
      <b-modal
        header-bg-variant="outline-success"
        style="border-radius: 10px;"
        id="modal-1"
        title="Registrar usuário"
        centered
        @hidden="closeModal(false)"
        hide-footer
      >
        <!-- Fechar Modal  Problema -->
        <modal-register-user
          @closeModal="closeModal"
          :id_="id_"
        ></modal-register-user>
      </b-modal>
    </div>
    <div>
      <b-modal
        header-bg-variant="secondary"
        style="border-radius: 10px;"
        id="modal-2"
        centered
        @hidden="closeModal(false)"
        title="Deletar registro"
        hide-footer
      >
        <modal-delete @isDelete="isDelete" :name="name"></modal-delete>
      </b-modal>
    </div>
    <div
      v-if="loadingScroll"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="table-responsive">
        <div
          @click.capture="setId(undefined)"
          v-b-modal.modal-1
          class="float-right mr-2 mb-2"
        >
          <b-button variant="success">
            <a>Novo</a>
          </b-button>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Função</th>
              <th scope="col">E-mail</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" style="text-align: center;">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.role }}</td>
              <td>{{ item.email }}</td>
              <td></td>
              <td></td>
              <td>
                <div class="d-flex justify-content-around">
                  <b-icon-pencil-square
                    v-b-modal.modal-1
                    @click="setId(item.id)"
                    class="actions"
                    style="color: #00f742;"
                  />

                  <b-icon-trash-fill
                    v-show="item.role !== 'admin'"
                    @click="setName(item.name), setId(item.id)"
                    v-b-modal.modal-2
                    class="actions"
                    style="color: #ff0000;"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <span>{{ this.totalSize }} itens no total</span>
        </div>
        <div class="float-right col-md-9 col-sm-12 d-flex justify-content-end">
          <div class="actions">
            <span @click="previousPage">« Anterior</span>
          </div>
          <div class="actions" @click="nextPage">
            <span>Próxima »</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ModalRegisterUser from "../../../components/Modal/ModalRegisterUser";
import ModalDelete from "../../../components/Modal/ModalDelete";
import Service from "../../../services";
export default {
  components: {
    "modal-register-user": ModalRegisterUser,
    ModalDelete
  },
  data() {
    return {
      data: [],
      length: 0,
      page: 1,
      totalPage: 1,
      totalSize: 0,
      loadingScroll: true,
      size: 1,
      id_: undefined,
      name: ""
    };
  },
  created() {
    this.populate(this.page);
  },
  methods: {
    populate(page) {
      const request = new Service();
      request.get("user", { page }).then(res => {
        this.totalSize = res.total;
        this.data = res.data;
        this.loadingScroll = false;
      });
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.populate(this.page);
      }
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalSize / 12)) {
        this.page = this.page + 1;
        this.populate(this.page);
      }
    },
    setId(id) {
      this.id_ = id;
    },
    setName(name) {
      this.name = name;
    },
    isDelete(value) {
      const request = new Service();
      if (value) {
        request.delete("user", this.id_).then(() => this.populate());
      }
      this.closeModal(false);
    },
    closeModal(value) {
      if (value) {
        this.$toast.open({
          position: "top",
          message: "Operação realizada com sucesso",
          type: "success"
        });
        this.populate();
      }
      this.id_ = undefined;

      this.$bvModal.hide("modal-1");
      this.$bvModal.hide("modal-2");
    }
  }
};
</script>
<style>
.actions {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
