<template>
  <section>
    <b-modal
      header-bg-variant="outline-success"
      style="border-radius: 10px"
      size="md"
      @ok="pressOk"
      id="modal-1"
      centered
      title="Cadastrar bairro/distrito"
    >
      <gmap-autocomplete
        placeholder="Digite o bairro e cidade"
        :select-first-on-enter="true"
        @input="district = $event.target.value"
        id="input-6"
        class="form-control"
        :value="district"
        @place_changed="setPlaceDistrict"
      ></gmap-autocomplete>
    </b-modal>
    <div
      v-if="blockButton"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <h4>
        <strong>Salvando dados, por favor aguarde...</strong>
      </h4>
      <b-spinner
        variant="success"
        type="grow"
        style="width: 3rem; height: 3rem"
      />
    </div>
    <div v-else>
      <h5>Dados do paciente</h5>
      <form class="was-validated" @submit.prevent="save()">
        <div class="form-row">
          <div class="col-md-6 col-sm-12 mb-3">
            <label for="validationCustom01">Nome</label>
            <input
              type="text"
              class="form-control"
              id="validationCustom01"
              placeholder="Nome"
              v-model="model.name"
              required
            />
          </div>
          <div class="col-md-3 col-sm-12 mb-3">
            <label for="validationCustom02">CPF</label>
            <b-form-input
              v-mask="'###.###.###-##'"
              class="form-control"
              id="validationCustom02"
              placeholder="CPF"
              name="cpf"
              v-model="model.cpf"
              :required="model.sus == ''"
            />
            <small v-if="model.sus != '' && model.cpf == ''">Opcional</small>
          </div>
          <div class="col-md-3 col-sm-12 mb-3">
            <label for="validationCustom03">Cartão do SUS</label>
            <b-form-input
              v-mask="'### #### #### ####'"
              class="form-control"
              id="validationCustom03"
              placeholder="Cartão do SUS"
              name="sus"
              v-model="model.sus"
              :required="model.cpf == ''"
            />
            <small v-if="model.cpf != '' && model.sus == ''">Opcional</small>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="validationCustom04">Data de nascimento</label>
            <input
              type="date"
              class="form-control"
              id="validationCustom04"
              placeholder="Nome"
              :max="new Date().toJSON().substring(0, 10)"
              min="1900-01-01"
              v-model="model.birth"
              required
            />
            <span v-if="age !== undefined">Idade: {{ age }} anos</span>
          </div>

          <div class="col-md-2 mb-3">
            <label for="validationCustom05">Sexo</label>
            <select
              v-model="model.gender"
              class="custom-select"
              id="validationCustom05"
              required
            >
              <option value="M">M</option>
              <option value="F">F</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="validationCustom06">Contato</label>
            <b-form-input
              v-mask="'(##)# ####-####'"
              class="form-control"
              id="validationCustom06"
              placeholder="Contato"
              v-model="model.contact"
              required
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3 mb-3">
            <label for="validationCustom07">Município do caso</label>
            <select
              v-model="model.city_id"
              class="custom-select"
              id="validationCustom07"
              required
            >
              <option
                v-for="(item, index) in cities"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-md-4 col-sm-12 col-12 mb-3">
            <label for="validationCustom06">Bairro/Distrito do caso</label>
            <select
              v-model="model.district_id"
              class="custom-select"
              id="validationCustom06"
              required
            >
              <option
                v-for="(item, index) in districts"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <div class="float-right" type="submit" v-b-modal.modal-1>
              <span style="font-size: 13px"
                >Não encontrou o bairro? Clique aqui</span
              >
              <!-- <b-icon-plus
                id="validationCustom06"
                scale="1.5"
                style="color:#2ecc71; border: 1px solid #2ecc71;border-radius: 5px"
              ></b-icon-plus>-->
            </div>
          </div>
          <div class="col-md-5 mb-3">
            <label for="validationCustom10">Endereço</label>
            <gmap-autocomplete
              ref="autocomplete"
              :disabled="
                model.district_id === undefined || model.city_id === undefined
              "
              :select-first-on-enter="true"
              @input="autocomplete = $event.target.value"
              id="validationCustom10"
              class="form-control"
              :value="autocomplete"
              placeholder="Ex.: Rua José de Alencar, 150"
              @blur="setPlace"
              @place_changed="setPlace"
              required
            ></gmap-autocomplete>
            <small>
              Lat: {{ model.latitude }}, Lng: {{ model.longitude }}
              {{ isDistrict == false ? "" : "(Bairro)" }}
            </small>
          </div>
        </div>
        <hr />
        <h5>Dados de Vacinação</h5>
        <div class="form-row">
          <div class="col-md-2 col-sm-12 mt-2">
            <label for="validationCustom32">COVID-19</label>
            <select
              v-model="model.vaccinated"
              class="custom-select"
              id="validationCustom32"
              required
            >
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </div>
        </div>
        <hr />

        <h5>Dados do caso</h5>
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="validationCustom11">Data da notificação</label>
            <input
              name="data"
              type="date"
              :max="new Date().toJSON().substring(0, 10)"
              min="2020-03-01"
              class="form-control"
              id="validationCustom11"
              @change="changeCreated_at"
              v-model="model.created_at"
              required
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationCustom12">Unidade notificadora</label>
            <select
              v-model="model.reporting_unity"
              class="custom-select"
              id="validationCustom12"
              required
            >
              <option
                v-for="(item, index) in reporting_unity"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="col-md-4 col-sm-12 mb-3">
            <label for="validationCustom16">Data dos primeiros sintomas</label>
            <input
              name="data"
              type="date"
              :max="model.created_at"
              min="2020-03-01"
              class="form-control"
              id="validationCustom16"
              v-model="model.onset_at"
              required
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="validationCustom17">Situação do caso</label>
            <select
              v-model="model.incident_type"
              @change="changeIncident"
              class="custom-select"
              id="validationCustom17"
              required
            >
              <option
                v-for="(item, index) in incident_type"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="col-md-4 col-sm-12 mb-3">
            <label for="validationCustom20">Data do óbito</label>
            <input
              name="data"
              type="date"
              :disabled="model.incident_type !== 'lethal'"
              required
              :max="new Date().toJSON().substring(0, 10)"
              min="2020-03-01"
              class="form-control"
              id="validationCustom20"
              v-model="model.death_date"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationCustom18">Situação do paciente</label>
            <select
              :disabled="model.incident_type == ''"
              v-if="['confirmed', 'lethal'].includes(model.incident_type)"
              v-model="model.interned"
              class="custom-select"
              id="validationCustom18"
              required
            >
              <option :value="0">Isolado</option>
              <option :value="1">Internado</option>
            </select>
            <select
              :disabled="model.incident_type == ''"
              v-else-if="model.incident_type == 'suspicious'"
              v-model="model.interned"
              class="custom-select"
              id="validationCustom18"
              required
            >
              <option :value="0">Isolado</option>
              <option :value="1">Internado</option>
              <option :value="2">Liberado</option>
            </select>
            <select
              :disabled="model.incident_type == ''"
              v-else
              class="custom-select"
              id="validationCustom12"
              required
            >
              <option :value="2">Liberado</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-sm-12 mb-3">
            <label for="validationCustom19">Data da internação</label>
            <input
              id="validationCustom19"
              name="data"
              type="date"
              class="form-control"
              :max="new Date().toJSON().substring(0, 10)"
              :disabled="model.interned !== 1"
              min="2020-03-01"
              v-model="model.interned_at"
              required
            />
          </div>
          <div class="col-md-6 col-sm-12 mb-3">
            <label for="validationCustom20">Data da alta de internação</label>
            <input
              name="data"
              type="date"
              :disabled="model.interned !== 1"
              :max="new Date().toJSON().substring(0, 10)"
              min="2020-03-01"
              class="form-control"
              id="validationCustom20"
              v-model="model.discharged_at"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-sm-12 mt-2">
            <label for="validationCustom21">Reinfectado?</label>
            <select
              v-model="model.reinfected"
              class="custom-select"
              id="validationCustom21"
              required
            >
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </div>
          <div class="row col-md-10 col-sm-12">
            <div class="col-md-6 col-sm-12">
              <label for="validationCustom20">Data da reinfecção</label>
              <input
                name="data"
                type="date"
                :disabled="model.reinfected !== true"
                :max="new Date().toJSON().substring(0, 10)"
                min="2020-03-01"
                class="form-control"
                id="validationCustom20"
                v-model="model.reinfected_at"
              />
            </div>
            <div class="col-md-6 col-sm-12">
              <label for="validationCustom20">Data do fim da reinfecção</label>
              <input
                name="data"
                type="date"
                :disabled="model.reinfected !== true"
                :max="new Date().toJSON().substring(0, 10)"
                min="2020-03-01"
                class="form-control"
                id="validationCustom20"
                v-model="model.reinfected_end"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label for="validationCustom21">Gestante?</label>
            <select
              :disabled="
                model.gender == undefined
                  ? true
                  : model.gender !== 'F'
                  ? true
                  : false
              "
              v-model="model.pregnant"
              class="custom-select"
              id="validationCustom21"
              required
            >
              <option
                v-for="(item, index) in pregnant"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-5 mb-3">
            <label for="validationCustom22">Sintomas</label>
            <multiselect
              id="validationCustom22"
              select-label="Selecionar"
              deselect-label="Remover"
              selected-label="Selecionado"
              placeholder="Selecione os sintomas"
              v-model="model.symptoms"
              :multiple="true"
              :close-on-select="false"
              :options="symptoms"
              label="name"
              track-by="id"
            ></multiselect>
          </div>
          <div class="col-md-5 mb-3">
            <label for="validationCustom23">Comorbidades prévias</label>
            <multiselect
              id="validationCustom23"
              select-label="Selecionar"
              deselect-label="Remover"
              selected-label="Selecionado"
              placeholder="Selecione as comorbidades prévias"
              v-model="model.morbities"
              :multiple="true"
              :close-on-select="false"
              :options="morbities"
              label="name"
              track-by="id"
            ></multiselect>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="validationCustom24">Observações</label>
            <textarea
              name="observacoes"
              type="text"
              class="form-control"
              id="validationCustom24"
              v-model="model.observations"
            />
          </div>
        </div>
        <hr />
        <h5>Dados do exame</h5>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label for="validationCustom25">Realizou exames?</label>
            <select
              v-model="model.examined"
              @change="changeExamined"
              class="custom-select"
              id="validationCustom25"
              required
            >
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationCustom26">Tipo de laboratório</label>
            <select
              :disabled="!model.examined"
              v-model="model.public_clinic"
              class="custom-select"
              id="validationCustom26"
              required
            >
              <option :value="0">Privado</option>
              <option :value="1">Público</option>
            </select>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationCustom27">Tipo de teste</label>
            <select
              :disabled="!model.examined"
              v-model="model.test_type"
              class="custom-select"
              id="validationCustom27"
              required
            >
              <option :value="'0'">Teste rápido - anticorpo</option>
              <option :value="'1'">Teste rápido - antígeno</option>
              <option :value="'2'">RT - PCR</option>
              <option :value="'3'">Enzimaimunoensaio - ELISA</option>
              <option :value="'4'">Eletroquimioluminescência - ECLIA</option>
              <option :value="'5'">Quimioluminescência- CLIA</option>
            </select>
          </div>

          <div class="col-md-4 mb-3">
            <label for="validationCustom28">Data da coleta</label>
            <input
              :disabled="!model.examined"
              name="data"
              type="date"
              :max="new Date().toJSON().substring(0, 10)"
              min="2020-03-01"
              class="form-control"
              id="validationCustom28"
              v-model="model.collected_at"
              required
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label for="validationCustom29">Recebeu exames?</label>
            <select
              v-model="model.received"
              class="custom-select"
              id="validationCustom29"
              required
            >
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </div>
          <div class="col-md-10 mb-3">
            <label for="validationCustom30">Data do resultado</label>
            <input
              :disabled="!model.received"
              name="data"
              type="date"
              :max="new Date().toJSON().substring(0, 10)"
              min="2020-03-01"
              class="form-control"
              id="validationCustom30"
              v-model="model.result_at"
              required
            />
          </div>
        </div>
        <div class="float-right mt-5">
          <b-button variant="secondary" @click="$emit('closeModal', false)"
            >Cancelar</b-button
          >
          <b-button
            :disabled="blockButton"
            variant="success"
            type="submit"
            class="ml-1"
          >
            <span v-if="!blockButton">Salvar</span>
            <b-spinner
              v-if="blockButton"
              variant="light"
              small
              label="Spinning"
            ></b-spinner>
          </b-button>
        </div>
      </form>
    </div>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
import Service from "../../services";
export default {
  props: {
    id_: {
      type: Number,
      default: undefined,
      required: false
    }
  },
  components: { Multiselect },
  data() {
    return {
      tempIsolated: null,
      isDistrict: false,
      age: undefined,
      notFind: false,

      interned: [
        { label: "Isolado", value: 0 },
        { label: "Internado", value: 1 },
        { label: "Liberado", value: 2 }
      ],
      loading: false,
      reporting_unity: [
        { label: "Atenção Primária", value: "0" },
        { label: "Hospital Público", value: "1" },
        { label: "Hospital Particular", value: "2" },
        { label: "Unidade de Pronto Atendimento (UPA)", value: "3" },
        { label: "Laboratório Privado", value: "4" }
      ],
      pregnant: [
        {
          label: "Sim",
          value: 1
        },
        { label: "Não", value: 0 }
      ],
      autocomplete: "",
      district: "",
      districts: [],
      incident_type: [
        { label: "Confirmado", value: "confirmed" },
        { label: "Suspeito", value: "suspicious" },
        { label: "Recuperado", value: "recovered" },
        { label: "Descartado", value: "discarded" },
        { label: "Óbito", value: "lethal" }
      ],
      cities: [],
      currentPlace: null,
      currentPlaceDistrict: null,
      blockButton: false,
      modelDistrict: {
        name: "",
        latitude: undefined,
        longitude: undefined,
        city_id: undefined
      },
      model: {
        cpf: "",
        sus: "",
        name: "",
        created_at: this.currentDate(),
        gender: "",
        pregnant: "",
        death_date: "",
        birth: "",
        district_id: undefined,
        street: "",
        test_type: "2",
        contact: "",
        latitude: "",
        longitude: "",
        onset_at: "",
        incident_type: "",
        reporting_unity: "0",
        isolated: "",
        result_at: "",
        discharged_at: "",
        observations: "",
        interned: 0,
        interned_at: "",
        user_id: "",
        city_id: null,
        public_clinic: 1,
        collected_at: this.currentDate(),
        accepted: false,
        symptoms: [],
        morbities: [],
        examined: true,
        received: false,
        reinfected: false,
        reinfected_at: "",
        reinfected_end: "",
        vaccinated: false
      },
      symptoms: [],
      morbities: []
    };
  },
  async created() {
    const local = JSON.parse(localStorage.getItem("user"));
    const local_city = JSON.parse(localStorage.getItem("user-meta"));
    this.model.user_id = local.id;
    this.model.city_id = local_city.id;

    const request = new Service();
    request.get("incident/symptoms").then(res => {
      this.symptoms = res;
    });
    request.get("incident/morbities").then(res => {
      this.morbities = res;
    });
    request.get("city", { size: 184 }).then(res => {
      this.cities = res.data.map(e => {
        return { name: e.name, id: e.id };
      });
    });

    let auxCollected_at = "";
    if (this.id_) {
      request.get(`incident/${this.id_}`).then(response => {
        response.city_id = response.city ? response.city[0].id : undefined;
        response.created_at = response.created_at.slice(0, 10);
        auxCollected_at = response.collected_at;
        response.received =
          response.examined == "1" && response.result_at != null ? true : false;
        response.examined = response.examined == "1" ? true : false;
        response.user_id = local.id;
        response.reinfected = !!response.reinfected;
        response.vaccinated = !!response.vaccinated;
        this.autocomplete = `${response.street}`;
        //  - ${response.district}, ${response.city[0].name}`;
        delete response.city;
        this.model = response;
      });
      this.model.collected_at = auxCollected_at;
    }
  },
  methods: {
    changeIncident() {
      if (
        this.model.incident_type != "suspicious" ||
        this.model.incident_type != "confirmed"
      ) {
        if (
          this.model.incident_type == "discarded" ||
          this.model.incident_type == "lethal" ||
          this.model.incident_type == "recovered"
        ) {
          this.model.interned = 2;
        } else {
          this.model.interned = 0;
        }
        this.model.interned_at = "";
      }
    },
    changeExamined() {
      if (this.model.examined == false) {
        this.model.received = false;
        this.model.result_at = "";
        this.model.collected_at = "";
        this.model.test_type = "";
        this.model.public_clinic = "";
      }
    },
    changeCreated_at() {
      if (this.model.examined == true)
        this.model.collected_at = this.model.created_at;
    },
    getDistrict() {
      const request = new Service();
      request.get(`city/districts/${this.model.city_id}`).then(res => {
        this.districts = res;
      });
    },
    closeModal() {
      this.$bvModal.hide("modal-search-map");
    },
    pressOk() {
      let aux = {
        name: "",
        latitude: undefined,
        longitude: undefined,
        city_id: undefined
      };
      if (this.modelDistrict.latitude == undefined) {
        aux = {
          name: this.district,
          latitude: "0",
          longitude: "0",
          city_id: this.model.city_id
        };
      } else {
        aux = {
          name: this.district,
          latitude: this.modelDistrict.latitude,
          longitude: this.modelDistrict.longitude,
          city_id: this.model.city_id
        };
      }
      if (this.district != "") {
        const request = new Service();
        request.post("city/districts", aux).then(() => {
          this.getDistrict();
          this.district = "";
          this.modelDistrict = {
            name: "",
            latitude: undefined,
            longitude: undefined,
            city_id: undefined
          };
        });
      }
    },
    setPlaceDistrict(place) {
      this.currentPlaceDistrict = place;

      let [Bairro] = place.formatted_address.split(",", 4);

      let obj = {
        name: Bairro,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        city_id: this.model.city_id
      };

      this.modelDistrict = obj;
    },
    setPlace(place) {
      this.currentPlace = place;

      setTimeout(() => {
        if (Object.keys(this.currentPlace).length > 1) {
          this.isDistrict = false;
          this.autocomplete = place.formatted_address;
          let [rua, numero] = place.name.split(",", 2);
          if (!this.notFind) this.model.street = `${rua},${numero}`;
          this.model.latitude = place.geometry.location.lat();
          this.model.longitude = place.geometry.location.lng();
        } else {
          this.notFind = true;
          this.isDistrict = true;
          this.model.street = this.autocomplete;
          let res = this.districts.filter(
            e => e.id === this.model.district_id
          )[0];

          this.model.latitude = res.latitude;
          this.model.longitude = res.longitude;
        }
      }, 500);
    },
    currentDate() {
      const now = new Date(Date.now());
      return `${now.getUTCFullYear()}-${("0" + (now.getUTCMonth() + 1)).substr(
        -2
      )}-${("0" + now.getDate()).substr(-2)}`;
    },
    async save() {
      this.blockButton = true;
      setTimeout(() => {
        let obj = Object.assign({}, this.model);

        obj.isolated = this.model.interned == 0 ? 1 : 0;
        obj.interned_at = this.model.interned_at || "2020-01-15";
        obj.created_at = this.model.created_at;
        obj.examined = this.model.examined ? 1 : 0;
        obj.public_clinic = this.model.examined
          ? this.model.public_clinic
          : null;
        obj.collected_at = this.model.examined ? this.model.collected_at : null;
        obj.result_at =
          this.model.examined && this.model.received
            ? this.model.result_at
            : null;
        obj.discharged_at = this.model.discharged_at || "2020-01-15";
        obj.symptoms =
          this.model.symptoms.length > 0
            ? JSON.stringify(
                this.model.symptoms.map(e => {
                  return e.id;
                })
              )
            : JSON.stringify([]);
        obj.morbities =
          this.model.morbities.length > 0
            ? JSON.stringify(
                this.model.morbities.map(e => {
                  return e.id;
                })
              )
            : JSON.stringify([]);
        const request = new Service();
        if (this.id_) {
          request
            .put(`incident/${obj.id}`, obj)
            .then(() => {
              this.$emit("closeModal", true);
            })
            .catch(e => {
              this.blockButton = false;
              this.$toast.open({
                position: "top",
                message: e.message,
                type: "error"
              });
            });
        } else {
          request
            .post("incident", obj)
            .then(() => {
              this.$emit("closeModal", true);
            })
            .catch(e => {
              this.blockButton = false;
              this.$toast.open({
                position: "top",
                message: e.message,
                type: "error"
              });
            });
        }
      }, 800);
    }
  },
  watch: {
    "model.birth": function() {
      let dob = new Date(this.model.birth);
      let diff_ms = Date.now() - dob.getTime();
      let age_dt = new Date(diff_ms);
      this.age = Math.abs(age_dt.getUTCFullYear() - 1970);
    },
    "model.gender": function() {
      if (this.model.gender == "M") {
        this.model.pregnant = 0;
      }
    },
    "model.city_id": function() {
      this.getDistrict();
    },
    "model.incident_type": function() {
      this.model.incident_type != "lethal"
        ? (this.model.death_date = "")
        : null;
      if (this.model.incident_type != "suspicious") {
        this.model.received = true;
      } else this.model.received = false;
    },
    "model.interned": function() {
      if (this.model.interned == 1) {
        this.model.isolated = 0;
      } else {
        this.model.interned_at = "";
        this.model.isolated = 1;
      }
    }
  }
};
</script>
<style>
.pac-container {
  z-index: 100000 !important;
}
.pac-logo {
  z-index: 100000 !important;
}
h5 {
  text-transform: uppercase;
}
.componentGoogle {
  width: 100%;
  border: 1px solid #999;
  /* height: 100%; */
  padding: 6px 0 6px 2px;
  border-radius: 3px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
