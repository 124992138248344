<template>
  <section>
    <div
      class="row d-flex justify-content-between pl-4 pr-4 align-items-center"
    >
      <h2>Bem-Vindo(a), {{ currentUser.name }}</h2>
      <b-button v-b-modal.modal-cp>
        <b-icon-lock-fill />Alterar Senha
      </b-button>
    </div>
    <hr />
    <div>
      <b-modal
        header-bg-variant="outline-success"
        style="border-radius: 10px"
        size="xl"
        id="modal-1"
        hide-footer
        centered
        scrollable
        @hidden="closeModal(false)"
        title="Registrar Caso"
      >
        <register-case :id_="id_" @closeModal="closeModal"></register-case>
      </b-modal>
    </div>
    <div>
      <change-password @closeModal="closeModal" />
    </div>
    <div>
      <b-modal
        header-bg-variant="secondary"
        style="border-radius: 10px"
        id="modal-2"
        centered
        @hidden="closeModal(false)"
        title="Deletar registro"
        hide-footer
      >
        <modal-delete
          @closeModal="closeModal"
          @isDelete="isDelete"
          :name="name"
        ></modal-delete>
      </b-modal>
    </div>
    <div>
      <b-modal
        header-bg-variant="outline-success"
        style="border-radius: 10px"
        id="modal-3"
        size="xl"
        centered
        @hidden="closeModal(false)"
        title="Informações Gerais"
        hide-footer
      >
        <modal-info :id_="id_"></modal-info>
      </b-modal>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <b-row class="col-md-12 col-sm-12 d-flex justify-content-center">
        <div class="col-md-2" v-for="(item, index) in items" :key="index">
          <b-card bg-variant="light">
            <div class="d-flex flex-column align-items-center">
              <h4 style="text-align: center">
                <b style="color: #000">{{ item.value }}</b>
              </h4>
              <h6 style="text-align: center">{{ item.label }}</h6>
              <img
                height="20px"
                :src="require(`../../assets/img/${item.img}.svg`)"
              />
            </div>
          </b-card>
        </div>
      </b-row>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="map col-md-12 mb-5 mt-5">
        <incidents ref="incidents" />
      </div>
      <div class="col-md-12">
        <div class="row mr-2">
          <div class="col-md-3 col-sm-12 col-12">
            <b-form-input
              debounce="500"
              type="text"
              name="leitos"
              class="form-control"
              id="searchHere"
              v-model="filter.query"
              placeholder="Buscar por nome, bairros e casos..."
            />
          </div>
          <div class="d-flex justify-content-end col-md-9 col-sm-12 col-12">
            <vue-excel-xlsx
              :data="requestExcelBody"
              :columns="requestExcelHeader"
              :filename="
                `Dados COVID-19 ${new Date().toLocaleDateString()} - ${city}`
              "
              :sheetname="`${city}`.slice(0, 31)"
              ref="excel_xlsx"
            ></vue-excel-xlsx>
            <b-button
              class="mr-2 d-flex justify-content-center align-items-center"
              variant="primary"
              @click="downloadAll"
            >
              <b-spinner
                v-if="meta.isDownloading"
                class="ml-2 mr-2"
                variant="light"
                small
              />
              <b-icon-download v-else />
            </b-button>
            <b-button class="mr-2" @click="avaiableReport" variant="primary">
              <div v-if="meta.isOperating" class="text-center">
                <b-spinner small variant="light" />
              </div>
              <div v-else>
                <b-icon-clipboard-data class="mr-1" />
                <a>Disponibilizar boletim</a>
              </div>
            </b-button>
            <b-button v-b-modal.modal-1 variant="success">
              <a>Novo caso</a>
            </b-button>
          </div>
        </div>
        <div class="showFilter">
          <div class="row">
            <b-form-group
              class="col-sm-6 col-6"
              label="Filtrar por"
              label-for="filterBy"
            >
              <b-form-select
                v-model="filter.orderBy"
                id="filterBy"
                :options="orderBy"
                text-field="label"
                value-field="value"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              class="col-sm-6 col-6"
              label="Ordem"
              label-for="filterByOrder"
            >
              <b-form-select
                id="filterByOrder"
                v-model="filter.isAsc"
                text-field="label"
                value-field="value"
                :options="[
                  { label: 'Ascendente', value: 1 },
                  { label: 'Descendente', value: 0 }
                ]"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="row d-flex justify-content-center">
            <b-button
              class="col-sm-8 col-8"
              variant="primary"
              @click="getIncidents()"
              >Aplicar</b-button
            >
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>
                <div type="submit" @click="changeOrderBy('name')">
                  <b class="d-flex flex-row align-items-center">
                    Nome
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'name' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'name' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <div type="submit" @click="changeOrderBy('birth')">
                  <b class="d-flex flex-row align-items-center">
                    Idade
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'birth' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'birth' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <div type="submit" @click="changeOrderBy('created_at')">
                  <b class="d-flex flex-row align-items-center">
                    Notificação em
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'created_at' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'created_at' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <div type="submit" @click="changeOrderBy('incident_type')">
                  <b class="d-flex flex-row align-items-center">
                    Situação do caso
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'incident_type' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'incident_type' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <b>Situação do paciente</b>
              </th>
              <th>
                <div type="submit" @click="changeOrderBy('onset_at')">
                  <b class="d-flex flex-row align-items-center">
                    Primeiros sintomas
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'onset_at' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'onset_at' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <b>Fim do isolamento</b>
              </th>
              <th>
                <div type="submit" @click="changeOrderBy('collected_at')">
                  <b class="d-flex flex-row align-items-center">
                    Coleta em
                    <div class="d-flex flex-column mt-2 ml-2">
                      <b-icon-chevron-up
                        :variant="
                          filter.orderBy == 'collected_at' && filter.isAsc == 0
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                        style="margin: -8px 0"
                      />

                      <b-icon-chevron-down
                        :variant="
                          filter.orderBy == 'collected_at' && filter.isAsc == 1
                            ? 'info'
                            : null
                        "
                        scale="0.8"
                      />
                    </div>
                  </b>
                </div>
              </th>
              <th>
                <b>Município notificador</b>
              </th>
              <th>
                <b>Ações</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td>
                <small>{{ item.name }}</small>
              </td>
              <td>
                <small>{{ item.birth | age }}</small>
              </td>
              <td>
                <small>{{ item.created_at | locale }}</small>
              </td>
              <td>
                <h5>
                  <small>
                    <b-badge
                      :style="`background: #${color(item.incident_type)};`"
                      >{{ typeEnum(item.incident_type) }}</b-badge
                    >
                  </small>
                </h5>
              </td>
              <td>
                <small>{{
                  typeEnumIncidentType(item.interned, item.incident_type)
                }}</small>
              </td>
              <td>
                <small>{{ item.onset_at | locale }}</small>
              </td>
              <td>
                <small>{{ item.onset_at | sumday(14) }} dias de isolamento</small>
              </td>
              <td>
                <small>{{ item.collected_at | locale }}</small>
              </td>
              <td>
                <small>{{ item.report_city.name }}</small>
              </td>
              <td>
                <div class="d-flex justify-content-around">
                  <b-icon-eye
                    @click="setId(item.id)"
                    v-b-modal.modal-3
                    class="actions"
                    style="color: #000"
                  />
                  <b-icon-pencil-square
                    @click="setId(item.id)"
                    class="actions"
                    style="color: #00f742"
                    v-b-modal.modal-1
                  />
                  <b-icon-trash-fill
                    @click="setName(item.name), setId(item.id)"
                    v-b-modal.modal-2
                    class="actions"
                    style="color: #ff0000"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-md-4 col-sm-6 col-6">
            <span>{{ this.totalSize }} itens no total</span>
          </div>
          <div class="col-md-4 col-sm-6 col-6">
            <span>
              Página {{ this.current_page }} de
              {{ Math.ceil(this.totalSize / 15) }}
            </span>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="d-flex justify-content-around">
              <div class="actions">
                <span @click="previousPage">« Anterior</span>
              </div>
              <div class="actions" @click="nextPage">
                <span>Próxima »</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mr-2 d-flex justify-content-center">
        <b-form-select
          v-model="state"
          :options="type"
          value-field="label"
          text-field="name"
        ></b-form-select>
      </div>
    </div>

    <div class="row pb-3">
      <div class="col-md-4 col-sm-12">
        <incidents-chart-age
          :city="currentMeta.id"
          :currentFilter="state"
          ref="incidents_chart_age"
        />
      </div>
      <div class="col-md-4 col-sm-12">
        <incidents-chart-gender
          :city="currentMeta.id"
          :currentFilter="state"
          ref="incidents_chart_gender"
        />
      </div>
      <div class="col-md-4 col-sm-12">
        <incidents-chart
          :city="currentMeta.id"
          :currentFilter="state"
          ref="incidents_chart"
        />
      </div>
    </div>
  </section>
</template>
<script>
import RegisterCase from "../../components/Modal/ModalRegisterCase";
import ChangePassword from "../../components/Modal/ModalChangePassword";
import Incidents from "../../components/Map/Incidents";
import Service from "../../services";
import ModalDelete from "../../components/Modal/ModalDelete";
import ModalInfo from "../../components/Modal/ModalInfo";
import IncidentsChart from "../../components/Charts/IncidentsChart";
import IncidentsChartAge from "../../components/Charts/IncidentsChartAge";
import IncidentsChartGender from "../../components/Charts/IncidentsChartGender";
import { mapGetters } from "vuex";
import envInfo from "../../../env.json";

export default {
  components: {
    RegisterCase,
    "incidents-chart": IncidentsChart,
    "incidents-chart-age": IncidentsChartAge,
    "incidents-chart-gender": IncidentsChartGender,
    incidents: Incidents,
    ModalDelete,
    ModalInfo,
    ChangePassword
  },
  data() {
    return {
      meta: {
        isOperating: false,
        isDownloading: false
      },
      requestExcelHeader: [
        { label: "Nome", field: "nome" },
        { label: "CPF", field: "cpf" },
        { label: "SUS", field: "sus" },
        { label: "Data de Nascimento", field: "data_de_nascimento" },
        { label: "Idade", field: "idade" },
        { label: "Gênero", field: "genero" },
        { label: "Gestante", field: "gestante" },
        { label: "Contato", field: "contato" },
        { label: "Cidade", field: "cidade" },
        { label: "Bairro", field: "bairro" },
        { label: "Endereço", field: "endereco" },
        { label: "Notificado em", field: "notificado_em" },
        { label: "Unidade notificadora", field: "unidade_notificadora" },
       /* { label: "Primeiros sintomas", field: "primeiros_sintomas" },*/
        { label: "Sintomas", field: "sintomas" },
        { label: "Comorbidades", field: "comorbidades" },
        { label: "Situação do caso", field: "situacao_do_caso" },
        { label: "Data do óbito", field: "data_obito" },
        { label: "Situação do paciente", field: "situacao_do_paciente" },
        { label: "Data da internação", field: "data_internacao" },
        { label: "Data da alta de internação", field: "data_alta_internacao" },
        { label: "Exame realizado", field: "exame_realizado" },
        { label: "Coleta em", field: "coleta_em" },
        { label: "Tipo de laboratório", field: "tipo_laboratorio" },
        { label: "Tipo de teste", field: "tipo_teste" },
        { label: "Data do resultado do exame", field: "data_resultado_exame" }
      ],
      requestExcelBody: [],
      filter: {
        orderBy: "name",
        isAsc: 1,
        query: "",
        page: 1
      },
      orderBy: [
        {
          label: "Nome",
          value: "name"
        },
        {
          label: "Idade",
          value: "birth"
        },
        {
          label: "Notificação em",
          value: "created_at"
        },
        {
          label: "Situação do caso",
          value: "incident_type"
        },
       /* {
          label: "Primeiros sintomas",
          value: "onset_at"
        },*/
        {
          label: "Coleta em",
          value: "collected_at"
        }
      ],
      colors: {
        confirmed: {
          name: "Confirmado",
          color: "EA3546"
        },
        suspicious: {
          name: "Suspeito",
          color: "F86624"
        },
        recovered: {
          name: "Recuperado",
          color: "00B963"
        },
        discarded: {
          name: "Descartado",
          color: "999"
        },
        lethal: {
          name: "Óbito",
          color: "000000"
        }
      },
      city: "",
      districts: [],
      items: [],
      current_page: 1,
      state: "confirmed",
      name: "",
      totalSize: 0,
      id_: undefined,
      user_id: undefined,
      data: [],
      currentPlace: null,
      type: [
        {
          name: "Todos",
          label: "all"
        },
        {
          name: "Confirmado",
          label: "confirmed"
        },
        {
          name: "Suspeito",
          label: "suspicious"
        },
        {
          name: "Recuperado",
          label: "recovered"
        },
        {
          name: "Descartado",
          label: "discarded"
        },
        {
          name: "Óbito",
          label: "lethal"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentMeta", "currentUser"])
  },
  async mounted() {
    const local = this.currentUser;
    const city = this.currentMeta;

    this.user_id = local.id;
    this.city = city.name;

    this.populate();
  },
  filters: {
    age(birth) {
      let today = new Date();
      let birthDate = new Date(birth);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    locale(date) {
      if (!date) return "Não";
      if (date instanceof Date) {
        return date.toLocaleDateString();
      }
      const [year, month, day] = date.split("-", 3);
      return `${day}/${month}/${year}`;
    },
    sumday(date, days = 0) {
      const [year, month, day] = date.split("-", 3);
      let result = new Date(year, month - 1, day);
      var newTime = new Date();
      var timeDiference = newTime.getTime() -result.getTime();
      var numberDias = parseInt(timeDiference/(1000 * 3600 * 24));
      //console.log(numberDias);
      result.setDate(result.getDate() + days);
      
      return numberDias;
    }
  },
  methods: {
    getDistrictByCity(id) {
      const aux = this.districts.filter(e => e.id === id)[0];
      if (aux != undefined) return aux.name;
    },
    getReportingUnity(index) {
      let reporting_unity = [
        { label: "Atenção Primária", value: "0" },
        { label: "Hospital Público", value: "1" },
        { label: "Hospital Particular", value: "2" },
        { label: "Unidade de Pronto Atendimento (UPA)", value: "3" },
        { label: "Laboratório Privado", value: "4" }
      ];
      let res = reporting_unity.filter(e => e.value == index)[0];
      if (res != undefined) return res.label;
    },
    getSintomas(sintomas) {
      if (sintomas.length > 0) {
        let aux = [];
        sintomas.forEach(e => aux.push(`${e.name}`));
        return aux.toString();
      }
      return "";
    },
    setLocale(date) {
      if (!date) return "Não";
      if (date instanceof Date) {
        return date.toLocaleDateString();
      }
      const [year, month, day] = date.split("-", 3);
      return `${day}/${month}/${year}`;
    },
    setSumday(date, days = 0) {
      const [year, month, day] = date.split("-", 3);
      let result = new Date(year, month - 1, day);
      result.setDate(result.getDate() + days);
      return result;
    },
    avaiableReport() {
      const request = new Service();
      this.meta.isOperating = true;
      request
        .post(`relatory/${this.currentMeta.id}`)
        .then(() => {
          this.meta.isOperating = false;
          window.open(
            `${envInfo.environments[envInfo.current_env].api_url}relatory/${
              this.currentMeta.id
            }`
          );
        })
        .catch(() => {
          this.meta.isOperating = false;
        });
    },
    returnDateLocale(date) {
      if (date == "2020-01-00") return "Não";
      if (!date) return "Não";
      if (date instanceof Date) {
        return date.toLocaleDateString();
      }
      const [year, month, day] = date.split("-", 3);
      return `${day}/${month}/${year}`;
    },
    returnAge(birth) {
      let today = new Date();
      let birthDate = new Date(birth);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    changeOrderBy(atribute) {
      this.filter.orderBy = atribute;
      this.filter.isAsc = this.filter.isAsc == 1 ? 0 : 1;
      this.getIncidents();
    },
    color(item) {
      return (this.colors[item] || { color: "CCC" }).color;
    },

    typeEnum(value) {
      const res = this.type.filter(e => e.label == value)[0];
      if (res != undefined) {
        return res.name || "";
      }
    },
    typeEnumIncidentType(situation, status = null) {
      if (
        status != null &&
        (status === "discarded" || status === "recovered")
      ) {
        return "Liberado";
      }
      switch (situation) {
        case 0:
          return "Isolado";
        case 1:
          return "Internado";
        case 2:
          return "Liberado";
      }
    },
    async getIncidents() {
      const request = new Service();

      request.get("incident", { ...this.filter }).then(res => {
        this.data = res.incidents.data;
        this.current_page = res.incidents.current_page;
        this.totalSize = res.incidents.total;
      });
    },
    async downloadAll() {
      this.meta.isDownloading = true;
      const request = new Service();
      request
      //  .get("incident/donwloadIncident")
      .get("incident/downloadAll")
        .then(requestForExcel => {
          let body = [];
          requestForExcel.incidents.forEach(e => {
           
            let aux = {
              nome: e.name,
              cpf: e.cpf,
              sus: e.sus,
              data_de_nascimento: this.returnDateLocale(e.birth),
              idade: `${this.returnAge(e.birth)} Anos`,
              genero: e.gender == "M" ? "Homem" : "Mulher",
              gestante: e.pregnant == 0 ? "Não" : "Sim",
              contato: e.contact,
              cidade: e.city.name,
              bairro: this.getDistrictByCity(e.district_id),
              endereco: e.street,
              notificado_em: this.returnDateLocale(e.created_at),
              unidade_notificadora: this.getReportingUnity(e.reporting_unity),
              primeiros_sintomas: this.returnDateLocale(e.onset_at),
              /*sintomas: this.getSintomas(e.symptoms),*/
              comorbidades: this.getSintomas(e.morbities),
              situacao_do_caso: this.typeEnum(e.incident_type),
              data_obito: this.returnDateLocale(e.death_date),
              situacao_do_paciente: this.typeEnumIncidentType(e.interned),
              data_internacao: this.returnDateLocale(e.interned_at),
              data_alta_internacao: this.returnDateLocale(e.discharged_at),
              exame_realizado: e.examined == "1" ? "Sim" : "Não",
              coleta_em: this.returnDateLocale(e.collected_at),
              tipo_laboratorio: e.public_clinic == "0" ? "Privado" : "Público",
              tipo_teste:
                e.test_type == 0
                  ? "Teste rápido - anticorpo"
                  : e.test_type == 1
                  ? "Teste rápido - antígeno"
                  : "RT - PCR",
              data_resultado_exame: this.returnDateLocale(e.result_at),
              fim_do_isolamento: this.setLocale(this.setSumday(e.onset_at, 14))
            };
           //console.log(aux);
            
            body.push(aux);
           
          });
          this.requestExcelBody = body;
          setTimeout(() => {
            this.$refs.excel_xlsx.exportExcel();
            this.meta.isDownloading = false;
          }, 300);
          
        });
    },
    async populate() {
      const request = new Service();
      this.getIncidents();
      request.get(`city/districts/${this.currentMeta.id}`).then(res => {
        this.districts = res;
      });
      request.get(`statistics/cities/${this.currentMeta.id}`).then(res => {
        let {
          c_qty_isolated,
          c_qty_interned,
          c_qty_lethal,
          c_qty_recovered,
          s_qty_isolated,
          s_qty_interned,
          qty_discarded
        } = res;

        let confirmed =
          c_qty_isolated + c_qty_interned + c_qty_lethal + c_qty_recovered;

        let suspicious = s_qty_isolated + s_qty_interned;

        let aux = [
          {
            label: "Confirmados",
            img: "line-red",
            value: confirmed
          },
          {
            label: "Suspeitos",
            img: "line-yellow",
            value: suspicious
          },
          {
            label: "Descartados",
            img: "line-grey",
            value: qty_discarded
          },
          {
            label: "Curados",
            img: "line-green",
            value: c_qty_recovered
          },
          {
            label: "Óbitos",
            img: "line-black",
            value: c_qty_lethal
          }
        ];
        this.items = aux;
      });
      this.$refs.incidents.populate(this.filter.query);
      this.$refs.incidents_chart_age.populate();
      this.$refs.incidents_chart_gender.populate();
      this.$refs.incidents_chart.populate();
    },
    previousPage() {
      if (this.filter.page > 1) {
        console.log(this.filter.page)
        this.filter.page = this.filter.page - 1;
        this.populate(this.filter.page);
      }
    },
    nextPage() {
      if (this.filter.page < Math.ceil(this.totalSize / 12)) {
        console.log(this.filter.page)
        this.filter.page = this.filter.page + 1;
        this.populate(this.filter.page);
      }
    },
    setId(id) {
      this.id_ = id;
    },
    setName(name) {
      this.name = name;
    },
    isDelete(value) {
      const request = new Service();
      if (value) {
        request.delete("incident", this.id_).then(() => {
          this.populate();
        });
      }
      this.closeModal(false);
    },
    resetModal() {
      this.id_ = undefined;
    },
    closeModal(value) {
      if (value) {
        this.$toast.open({
          position: "top",
          message: "Operação realizada com sucesso",
          type: "success"
        });
        this.populate();
      }
      this.id_ = undefined;
      this.$root.$emit("bv::hide::modal", "modal-1", "#btnShow");
      this.$root.$emit("bv::hide::modal", "modal-2", "#btnShow");
      this.$root.$emit("bv::hide::modal", "modal-3", "#btnShow");

      // this.$bvModal.hide("modal-1");
      // this.$bvModal.hide("modal-2");
    }
  },
  watch: {
    "filter.query": function() {
      this.filter.page = 1;
      this.populate();
    }
  }
};
</script>
<style scoped>
p {
  font-size: 17px;
}
.cardType {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
  margin: 0 5px 0 5px;
  border-radius: 5px;
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
.showFilter {
  display: none;
}
th {
  background: #ddd;
  color: white;
  font-weight: bold;
}
td,
th {
  border: none;

  padding: 6px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .showFilter {
    display: block;
  }
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    border-radius: 2px;
    display: block;
  }
  tr {
    margin: 10px 0;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin-top: 10px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td:nth-of-type(1):before {
    content: "Nome";
  }
  td:nth-of-type(2):before {
    content: "Idade";
  }
  td:nth-of-type(3):before {
    content: "Notificação em";
  }

  td:nth-of-type(4):before {
    content: "Situação do caso";
  }

  td:nth-of-type(5):before {
    content: "Situação do paciente";
  }
  td:nth-of-type(6):before {
    content: "Primeiros sintomas";
  }
  td:nth-of-type(7):before {
    content: "Fim do isolamento em:";
  }
  td:nth-of-type(8):before {
    content: "Coleta em";
  }
  td:nth-of-type(9):before {
    content: "Município notificador";
  }

  td:nth-of-type(10):before {
    content: "Ações";
  }
}
</style>
