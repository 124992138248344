<template>
  <section>
    <!-- id	user_id	city_id	name	latitude	longitude	updated_at	created_at	 -->

    <form class="was-validated" @submit.prevent="save">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom01">Nome</label>
          <input
            type="text"
            name="nome"
            class="form-control"
            id="validationCustom01"
            placeholder="Nome"
            v-model="model.name"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom02">Contato</label>
          <b-form-input
            type="text"
            name="contato"
            v-mask="'(##)# ####-####'"
            class="form-control"
            id="validationCustom02"
            placeholder="Contato"
            v-model="model.contact"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom03">Endereço</label>
          <input
            type="text"
            name="endereco"
            class="form-control"
            id="validationCustom03"
            placeholder="Endereço"
            v-model="model.address"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom04">Usuário</label>
          <select
            name="usuario"
            id="validationCustom04"
            class="custom-select"
            required
            v-model="model.user_id"
          >
            <option
              v-for="(item, index) in users"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom05">Cidade</label>
          <select
            name="cidade"
            id="validationCustom05"
            class="custom-select"
            required
            v-model="model.city_id"
          >
            <option
              v-for="(item, index) in cities"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="float-right">
        <b-button variant="secondary" @click="$emit('closeModal', false)"
          >Cancelar</b-button
        >
        <b-button variant="success" type="submit" class="ml-1">Salvar</b-button>
      </div>
    </form>
  </section>
</template>
<script>
import Service from "../../services";
export default {
  props: {
    id_: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      users: [],
      cities: [],
      model: {
        name: "",
        city_id: undefined,
        user_id: undefined,
        latitude: 0.0,
        longitude: 0.0
      }
    };
  },
  created() {
    const request = new Service();
    request.get("/user", { role: "store" }).then(res => {
      this.users = res.data;
    });
    request.get("/city").then(res => {
      this.cities = res.data;
    });
    if (this.id_) {
      request.get(`store/${this.id_}`).then(res => {
        this.model = res;
      });
    }
  },
  methods: {
    async save() {
      let obj = Object.assign({}, this.model);

      const request = new Service();
      if (obj.id) {
        request
          .put(`store/${obj.id}`, obj)
          .then(() => {})
          .then(() => this.$emit("closeModal", true));
      } else {
        request
          .post("store", obj)
          .then(() => {})
          .then(() => this.$emit("closeModal", true));
      }
    }
  },
  watch: {}
};
</script>
<style>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
